import { RiskPlace } from 'config/api/assetRisks/assetRisks.types';
import asset_risk_messages from 'messages/asset_risk_messages';
import assets_messages from 'messages/assets_messages';
import { StaticTableFilter } from 'types/TableFilter';

const filters: { id: RiskPlace; name: string[] }[] = [
  { id: 'external', name: asset_risk_messages.risk_place.external },
  { id: 'internal', name: asset_risk_messages.risk_place.internal },
  { id: 'third_party', name: asset_risk_messages.risk_place.third_party },
];

const riskPlaceFilter: StaticTableFilter = {
  isDynamic: false,
  sectionName: assets_messages.asset_tasks_table.asset_place_filter_name,
  filters,
  isSingleSelect: false,
};

export default riskPlaceFilter;
