import React, { useCallback, useMemo, useState } from 'react';

import { IconButton, Typography } from '@material-ui/core';
import { format } from 'date-fns';
import PubSub from 'pubsub-js';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { generatePath } from 'react-router-dom';

import { ReactComponent as MoreIcon } from 'assets/icons/more.svg';
import AddNewRiskAnalysisDialog from 'components/_dialogs/AddNewRiskAnalysisDialog/AddNewRiskAnalysisDialog';
import ColoredButton from 'components/ColoredButton';
import ContextMenu from 'components/ContextMenu/ContextMenu';
import FooterButton from 'components/FooterButton';
import Section from 'components/Section';
import Table from 'components/Table';
import TextButton from 'components/TextButton/TextButton';
import activeUserRiskAnalysesApi, { legacyActiveUserRiskAnalysesApi } from 'config/api/activeUserRiskAnalyses/activeUserRiskAnalyses';
import { parseAllActiveUserRiskAnalysesForFE } from 'config/api/activeUserRiskAnalyses/activeUserRiskAnalyses.parsers';
import type { ActiveUserRiskAnalysis } from 'config/api/activeUserRiskAnalyses/activeUserRiskAnalyses.types';
import DATE_FORMAT from 'config/constants/DATE_FORMAT';
import EVENTS from 'config/events/pubsub';
import createUrlWithParams from 'helpers/createUrlWithParams';
import useBoolState from 'hooks/useBoolState';
import usePagination from 'hooks/usePagination';
import usePrepareAvailableFilters from 'hooks/usePrepareAvailableFilters/usePrepareAvailableFilters';
import useSubscription from 'hooks/useSubscription';
import asset_risk_messages from 'messages/asset_risk_messages';
import general_messages from 'messages/general_messages';
import { useConfirmationModalContext } from 'reactContext/ConfirmationModalContext/ConfirmationModalContext';
import { useHelpDialogContext } from 'reactContext/HelpDialogContext/HelpDialogContext';
import PATHS from 'router/PATHS';
import userAssetsWithTasksFilterFactory from 'services/_filters/parsers/_assetsFilters/userAssetsWithTasksFilter';
import riskAnalysisStatusFilter from 'services/_filters/parsers/_riskAnalysisFilters/riskAnalysisStatus';
import parseFiltersForRequest from 'services/parseFiltersForRequest';
import userDataStorage from 'storages/userDataStorage';
import { TableDataMappingRow } from 'types/Table';

import useStyles from './MyAssetsRiskAnalysisTable.styles';

const MyAssetsRiskAnalysisTable: React.FC = () => {
  // @ts-ignore
  const { id: userId } = userDataStorage.get() || { id: null };

  const { t } = useTranslation();
  const { showHelpDialog } = useHelpDialogContext();
  const { showConfirmationModal } = useConfirmationModalContext();

  const [sort, setSort] = useState<{ key: string | null; direction: string | null }>({ key: null, direction: null });
  const onSort = (key: string, direction: string) => setSort({ key, direction });
  const { state: isAddDialogOpen, setTrue: openAddDialog, setFalse: closeAddDialog } = useBoolState(false);

  const availableFilters = usePrepareAvailableFilters({
    status: riskAnalysisStatusFilter,
    asset: userAssetsWithTasksFilterFactory('riskAnalyses', { asset__owner: userId }),
  });
  const [filters, setFilters] = useState({
    asset__owner: [{ id: userId }],
  });

  const {
    data: tableData,
    getData: getTableData,
    hasNextPage,
    loading,
    refreshData,
  } = usePagination({
    endpointFunc: legacyActiveUserRiskAnalysesApi.getAllActiveUserRiskAnalyses,
    limits: [5, 5, 5],
    isGet: true,
    dependencies: [filters],
    sort,
    filters: parseFiltersForRequest(filters, {}, availableFilters || {}),
  });

  const statusChangeMutation = useMutation(activeUserRiskAnalysesApi.setStatusOfRiskAnalysis);
  const removeMutation = useMutation(activeUserRiskAnalysesApi.removeRiskAnalysis);

  useSubscription(EVENTS.MY_ASSET_RISKS_UPDATED, refreshData);

  const getRowHref = (row: ActiveUserRiskAnalysis) => generatePath(PATHS.RISK_ANALYSIS_DETAIL, { riskAnalysisId: row.id });

  const renderContextMenu = useCallback(
    (assetInIncidentReport: ActiveUserRiskAnalysis) => {
      const elements = [
        {
          label: t(asset_risk_messages.dashboard_table.context_menu_edit),
          onClick: () => window.open(createUrlWithParams(getRowHref(assetInIncidentReport), { isEdit: true }), '_blank'),
          disabled: assetInIncidentReport.status !== 'in_progress',
        },
        {
          label: t(asset_risk_messages.dashboard_table.context_menu_reopen),
          onClick: async () => {
            await statusChangeMutation.mutateAsync({ newStatus: 'in_progress', analysisId: assetInIncidentReport.id });
            PubSub.publish(EVENTS.MY_ASSET_RISKS_UPDATED);
          },
          disabled: !assetInIncidentReport.canBeReopened,
        },
        {
          label: t(asset_risk_messages.dashboard_table.context_menu_delete),
          onClick: async () => {
            const confirmed = await showConfirmationModal({
              title: t(asset_risk_messages.delete_confirmation_title),
              body: t(asset_risk_messages.delete_confirmation_body),
            });
            if (confirmed) {
              await removeMutation.mutateAsync(assetInIncidentReport.id);
              PubSub.publish(EVENTS.MY_ASSET_RISKS_UPDATED);
            }
          },
          disabled: !assetInIncidentReport.canBeDeleted,
        },
      ];

      return (
        <ContextMenu elements={elements} id={`assets-table-row-${assetInIncidentReport.id}`}>
          <IconButton>
            <MoreIcon />
          </IconButton>
        </ContextMenu>
      );
    },
    [tableData],
  );

  const dataMapping: TableDataMappingRow<ActiveUserRiskAnalysis>[] = useMemo(
    () => [
      {
        label: t(asset_risk_messages.dashboard_table.column_asset_name),
        get: data => data.asset.name,
        id: 'asset__name',
        width: '30%',
      },
      {
        label: t(asset_risk_messages.dashboard_table.column_analysis_date),
        get: data => format(data.date, DATE_FORMAT.defaultDate),
        id: 'date',
        width: '15%',
      },
      {
        label: t(asset_risk_messages.dashboard_table.column_analysis_status),
        get: data => t(asset_risk_messages.status[data.status]),
        id: 'status',
        width: '15%',
      },
      {
        label: t(asset_risk_messages.dashboard_table.column_risks_tasks_number),
        get: data => data.riskTasksCount,
        id: 'risk_tasks_count',
        width: '17%',
      },
      {
        label: t(asset_risk_messages.dashboard_table.column_risks_number),
        get: data => data.risksCount,
        id: 'risks_count',
        width: '17%',
      },
      {
        id: 'context',
        width: '6%',
        label: '',
        isAddon: true,
        get: (data): React.ReactNode => renderContextMenu(data) || ' ',
        blockSorting: true,
      },
    ],
    [],
  );

  const showMyAssetRisksHelpDialog = () => {
    showHelpDialog({
      title: t(asset_risk_messages.dashboard_table.introduction_title),
      body: t(asset_risk_messages.dashboard_table.introduction_body),
    });
  };

  const styles = useStyles();
  return (
    <Section
      showSeparator
      smallPadding
      title={asset_risk_messages.dashboard_table.title}
      titleLineAddon={[
        <TextButton key='introduction' onClick={showMyAssetRisksHelpDialog}>
          {t(asset_risk_messages.dashboard_table.introduction_button)}
        </TextButton>,
        // @ts-ignore
        <ColoredButton key='add_new' customColor='secondary' onClick={openAddDialog}>
          {t(asset_risk_messages.dashboard_table.add_new_button)}
        </ColoredButton>,
      ]}
    >
      {!tableData && !loading ? (
        <Typography align='center' className={styles.emptyFeedback}>
          {t(asset_risk_messages.dashboard_table.empty_list)}
        </Typography>
      ) : (
        <>
          <Table
            clickableRow
            data={tableData && parseAllActiveUserRiskAnalysesForFE(tableData)}
            dataMapping={dataMapping}
            filtersService={{ filters, setFilters, availableFilters }}
            getRowHref={getRowHref}
            onSort={onSort}
            openInNewTab
            refetching={loading}
            sort={sort}
          />
          {hasNextPage && (
            // @ts-ignore
            <FooterButton loading={loading} onClick={getTableData}>
              {t(general_messages.show_more)}
            </FooterButton>
          )}
        </>
      )}
      <AddNewRiskAnalysisDialog onClose={closeAddDialog} open={isAddDialogOpen} />
    </Section>
  );
};

export default MyAssetsRiskAnalysisTable;
