import React, { useMemo, useState } from 'react';

import { Container, Theme, useMediaQuery, useTheme } from '@material-ui/core';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';

import AssetTaskDialog from 'components/_dialogs/AssetTaskDialog/AssetTaskDialog';
import CarouselWrapper from 'components/CarouselWrapper/CarouselWrapper';
import ColoredTile from 'components/ColoredTile';
import FooterButton from 'components/FooterButton';
import Loader from 'components/Loader';
import MobileGuttersContainer from 'components/MobileGuttersContainer';
import Section from 'components/Section';
import Table from 'components/Table';
import TextButton from 'components/TextButton/TextButton';
import UserAvatar from 'components/UserAvatar/UserAvatar';
import organizationAssetTasksApi, { legacyOrganizationAssetTasksApi } from 'config/api/organizationAssetTasks/organizationAssetTasks';
import { parseAllOrganizationAssetTasksForFE } from 'config/api/organizationAssetTasks/organizationAssetTasks.parsers';
import { OrganizationAssetTasksInList, OrganizationAssetTasksStats } from 'config/api/organizationAssetTasks/organizationAssetTasks.types';
import QUERY_KEYS from 'config/api/QUERY_KEYS';
import DATE_FORMAT from 'config/constants/DATE_FORMAT';
import safeT from 'helpers/safeT/safeT';
import usePagination from 'hooks/usePagination';
import usePrepareAvailableFilters from 'hooks/usePrepareAvailableFilters/usePrepareAvailableFilters';
import assets_messages from 'messages/assets_messages';
import general_messages from 'messages/general_messages';
import title_messages from 'messages/title_messages';
import { useHelpDialogContext } from 'reactContext/HelpDialogContext/HelpDialogContext';
import assetsImportanceFilter from 'services/_filters/parsers/_assetsFilters/assetsImportanceFilter';
import assetsTaskActivityFilterFactory from 'services/_filters/parsers/_assetTasksFilters/assetsTaskActivityFilterFactory';
import assetsTaskAssetNameFilterFactory from 'services/_filters/parsers/_assetTasksFilters/assetsTaskAssetNameFilterFactory';
import assetsTaskOwnerFilterFactory from 'services/_filters/parsers/_assetTasksFilters/assetsTaskOwnerFilterFactory';
import assetTaskStatusFilter from 'services/_filters/parsers/_assetTasksFilters/assetTaskStatusFilter';
import isOverdueTaskFilter from 'services/_filters/parsers/_assetTasksFilters/isOverdueTaskFilter';
import parseFiltersForRequest from 'services/parseFiltersForRequest';
import { TableDataMappingRow } from 'types/Table';

const resolveColor = (theme: Theme, key: keyof OrganizationAssetTasksStats) => {
  switch (key) {
    case 'overdue':
      return theme.palette.error.light;
    case 'notStarted':
      return theme.palette.grey['300'];
    case 'started':
      // @ts-ignore
      return theme.palette.secondary['100'];
    case 'finished':
      // @ts-ignore
      return theme.palette.secondary['200'];
    default:
      return theme.palette.primary.main;
  }
};

const AssetTaskDashboardPage: React.FC = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  // @ts-ignore
  const upMd = useMediaQuery(({ breakpoints }) => breakpoints.up('md'));

  const { data: kpiData, isLoading } = useQuery(
    QUERY_KEYS.GET_ORGANIZATION_ASSET_TASK_KPI,
    organizationAssetTasksApi.getOrganizationAssetTasksStats,
    {
      placeholderData: {
        finished: 0,
        notStarted: 0,
        overdue: 0,
        started: 0,
      },
    },
  );

  const availableFilters = usePrepareAvailableFilters({
    status: assetTaskStatusFilter,
    is_overdue: isOverdueTaskFilter,
    asset__info__assets_importance: assetsImportanceFilter,

    year_planner_activity__activity: assetsTaskActivityFilterFactory('assetTasks'),
    asset__owner: assetsTaskOwnerFilterFactory('assetTasks'),
    asset__name: assetsTaskAssetNameFilterFactory('assetTasks'),
  });

  const [sort, setSort] = useState<{ key: string | null; direction: string | null }>({ key: null, direction: null });
  const onSort = (key: string, direction: string) => setSort({ key, direction });
  const [filters, setFilters] = useState({});

  const {
    data: tableData,
    getData: getTableData,
    hasNextPage,
    loading,
    initialLoading,
  } = usePagination({
    endpointFunc: legacyOrganizationAssetTasksApi.getAllOrganizationAssetTasks(),
    limits: [15, 10, 5],
    isGet: true,
    dependencies: [sort, filters],
    sort,
    filters: parseFiltersForRequest(filters, {}, availableFilters || {}),
  });

  const dataMapping: TableDataMappingRow<OrganizationAssetTasksInList>[] = useMemo(
    () => [
      {
        label: t(assets_messages.asset_tasks_table.asset_name),
        get: ({ asset }) => asset.name,
        id: 'asset__name',
        width: '25%',
      },
      {
        label: t(assets_messages.asset_tasks_table.owner),
        get: ({ asset }) => <UserAvatar lazy showName userData={asset.owner.profile} userId={asset.owner.id} />,
        id: 'asset__owner__full_name',
        width: '20%',
      },
      {
        label: t(assets_messages.asset_tasks_table.activity),
        get: ({ yearPlannerActivity }) => safeT(t, yearPlannerActivity.activity.nameTranslationKey, yearPlannerActivity.activity.name),
        id: 'year_planner_activity__activity__translated_name',
        width: '25%',
      },
      {
        label: t(assets_messages.asset_tasks_table.due_date),
        get: ({ dueDate }) => format(dueDate, DATE_FORMAT.defaultDate),
        id: 'due_date',
        width: '15%',
      },
      {
        label: t(assets_messages.asset_tasks_table.status),
        get: ({ status }) => t(assets_messages.asset_task_status[status]),
        id: 'status',
        width: '15%',
      },
    ],
    [],
  );

  const { showHelpDialog } = useHelpDialogContext();

  const showYearlyAssetActivitiesHelpDialog = () => {
    showHelpDialog({
      title: t(assets_messages.asset_tasks_introduction_dashboard.title),
      body: t(assets_messages.asset_tasks_introduction_dashboard.body),
    });
  };

  const [selectedTaskId, setSelectedTaskId] = useState<number | null>(null);
  const isTaskDetailsDialogOpen = selectedTaskId !== null;
  const closeTaskDetailsDialog = () => setSelectedTaskId(null);

  return (
    <Container disableGutters={!upMd} maxWidth='lg'>
      <MobileGuttersContainer>
        <Section
          paddingTop={3}
          smallPadding
          title={title_messages.asset_tasks_table}
          titleLineAddon={[
            <TextButton key='introduction' onClick={showYearlyAssetActivitiesHelpDialog}>
              {t(assets_messages.asset_tasks_introduction_dashboard.button)}
            </TextButton>,
          ]}
        />
      </MobileGuttersContainer>
      <CarouselWrapper>
        {Object.entries(kpiData!).map(([key, value]) => (
          <ColoredTile
            key={key}
            centered
            color={resolveColor(theme, key as keyof OrganizationAssetTasksStats)}
            description={t(assets_messages.tasks_kpi[key as keyof OrganizationAssetTasksStats])}
            disableRipple
            isLoading={isLoading}
            title={value}
          />
        ))}
      </CarouselWrapper>
      <MobileGuttersContainer>
        {initialLoading ? (
          <Loader inner />
        ) : (
          <Table
            data={tableData && parseAllOrganizationAssetTasksForFE(tableData)}
            dataMapping={dataMapping}
            filtersService={{ availableFilters, filters, setFilters }}
            fixedHeader
            onRowClick={(_, element) => setSelectedTaskId(element.id)}
            onSort={onSort}
            refetching={loading && !initialLoading}
            sort={sort}
          />
        )}
        {hasNextPage && (
          // @ts-ignore
          <FooterButton loading={loading} onClick={getTableData}>
            {t(general_messages.show_more)}
          </FooterButton>
        )}
      </MobileGuttersContainer>
      <AssetTaskDialog forActiveUser={false} onClose={closeTaskDetailsDialog} open={isTaskDetailsDialogOpen} taskId={selectedTaskId} />
    </Container>
  );
};

export default AssetTaskDashboardPage;
