import axios from 'axios';
import { format } from 'date-fns';

import convertObjectToFormData from 'helpers/convertObjectToFormData';
import createUrlWithParams from 'helpers/createUrlWithParams';

import DATE_FORMAT from '../../constants/DATE_FORMAT';
import METHOD from '../_methods';
import { BasePaginatedQueryParams } from '../_types/BasePaginatedQueryParams';

import {
  parseSingleManagementReportsForFE,
  parseAllManagementReportsForFE,
  parseManagementReportPartialUpdate,
  parseManagementReportSecurityPartialUpdate,
} from './managementReports.parsers';
import type ManagementReportsApi from './managementReports.types';

const endpoint = 'api/v1/management_reports/';

export const legacyManagementReportsApi = {
  getAllManagementReports: (params: BasePaginatedQueryParams) => ({
    url: createUrlWithParams(endpoint, params),
    method: METHOD.GET,
  }),
};

const managementReportsApi: ManagementReportsApi = {
  getAllManagementReports: async () => {
    const { data } = await axios.get(endpoint);
    return parseAllManagementReportsForFE(data);
  },
  getSingleManagementReport: async id => {
    const { data } = await axios.get(`${endpoint}${id}/`);
    return parseSingleManagementReportsForFE(data);
  },
  removeManagementReport: id => axios.delete(`${endpoint}${id}/`),
  createManagementReport: formData => {
    const data = parseManagementReportPartialUpdate(formData);
    return axios.post(endpoint, data);
  },
  partialUpdateManagementReport: id => async formData => {
    const data = parseManagementReportPartialUpdate(formData);
    return axios.patch(`${endpoint}${id}/`, data);
  },
  partialUpdateManagementReportSecurity: id => async formData => {
    const data = convertObjectToFormData(parseManagementReportSecurityPartialUpdate(formData));
    return axios.patch(`${endpoint}${id}/security_report/`, data);
  },
  checkOverlapping: async ({ dateFrom, dateTo }) => {
    const { data } = await axios.post<{ id: number; date_from: string; date_to: string }[]>(`${endpoint}overlapping_reports/`, {
      date_from: format(dateFrom, DATE_FORMAT.defaultDate),
      date_to: format(dateTo, DATE_FORMAT.defaultDate),
    });
    return data.map(({ id, date_from, date_to }) => ({
      id,
      dateFrom: new Date(date_from),
      dateTo: new Date(date_to),
    }));
  },
};

export default managementReportsApi;
