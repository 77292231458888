import React, { useEffect, useState } from 'react';

import { CircularProgress, Tooltip, useMediaQuery } from '@material-ui/core';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import clsx from 'clsx';
import { isNil } from 'lodash';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import Filters from 'components/Filters';
import instances from 'config/constants/instances';
import DIRECTIONS from 'config/constants/sortDirections';
import resolveDirection from 'helpers/resolveDirection';
import search_messages from 'messages/search_messages';
import table_messages from 'messages/table_messages';

import InputWithDebouncedAction from '../InputWithDebouncedAction/InputWithDebouncedAction';

import useStyles from './Table.styles';

const getSortIcon = (direction, className) => {
  switch (direction) {
    case DIRECTIONS.ASC:
      return <KeyboardArrowDownIcon className={className} />;
    case DIRECTIONS.DESC:
      return <KeyboardArrowUpIcon className={className} />;
    default:
      return null;
  }
};

const Table = ({
  data: dataFromProps,
  dataMapping,
  filtersService,
  onRowClick,
  sort,
  onSort,
  hideThead,
  fixedHeader,
  getRowHref,
  clickableRow,
  refetching,
  enableSearch,
  onSearch,
  openInNewTab,
  disableClickAction,
  isRowSelected,
  keyGenerator,
}) => {
  const history = useHistory();
  const { t } = useTranslation();
  const isPivoted = useMediaQuery('@media screen and (max-width: 55em)');

  const [data, setData] = useState(null);
  useEffect(() => {
    if (dataFromProps) setData(dataFromProps);
  }, [dataFromProps]);
  const noData = data && !data.length;

  const initiateSort = (id, customSort) => {
    if (customSort) {
      return customSort(id);
    }
    return onSort(id, resolveDirection(sort?.direction, id === sort?.key));
  };
  const onThClick = (event, { id, customSort }) => {
    initiateSort(id, customSort);
  };

  const rowClickHandler = (event, row) => {
    if (disableClickAction && disableClickAction(row)) return;
    if (onRowClick) onRowClick(event, row);
    if (getRowHref && clickableRow) {
      const href = getRowHref(row);
      if (href) {
        if (openInNewTab) {
          window.open(href, '_blank');
        } else {
          history.push(href);
        }
      }
    }
  };

  useEffect(() => {
    if (process.env.REACT_APP_INSTANCE !== instances.PRODUCTION) {
      const colsSum = dataMapping.reduce((acc, curr) => acc + parseInt(curr.width || 0, 10), 0);
      // eslint-disable-next-line no-console
      if (colsSum > 100) console.error(`Sum of columns width should be less than or equal 100, now is ${colsSum}`);
      if (colsSum < 100 && !dataMapping.some(({ width }) => !width))
        // eslint-disable-next-line no-console
        console.error(`Width of all columns are set, sum of it should equal 100, now is ${colsSum}`);
    }
  }, [dataMapping]);

  const styles = useStyles({ fixedHeader, refetching, isPivoted });
  return (
    <div className={styles.tableWrapper}>
      {filtersService && isPivoted && (
        <div className={styles.filtersButton}>
          <Filters {...filtersService} />
        </div>
      )}
      <table className={styles.table}>
        {!hideThead && (
          <thead className={styles.thead}>
            {enableSearch && (
              <tr className={styles.searchBar}>
                <th className={clsx(styles.th, styles.thSearch)} colSpan='100%'>
                  <InputWithDebouncedAction action={onSearch} formControl={{ size: 'small' }} label={t(search_messages.input_label)} />
                </th>
              </tr>
            )}
            {!isPivoted && (
              <tr>
                {dataMapping.map(({ label, id, customSort, blockSorting, width, isAddon }) => {
                  const isSorted = !blockSorting && sort?.key === id && sort?.direction;
                  if (isAddon) return null;
                  return (
                    <th
                      key={`${label}-${id}`}
                      className={clsx(styles.th, isSorted && styles.sort, blockSorting && styles.blockSorting)}
                      onClick={blockSorting ? undefined : event => onThClick(event, { id, customSort })}
                      width={isPivoted ? undefined : width}
                    >
                      <div className={styles.thContent}>
                        <span>{label}</span>
                        {isSorted && sort?.additionalInfo && <span className={styles.additionalInfo}>{sort.additionalInfo}</span>}
                        <span className={styles.sortIconWrapper}>{getSortIcon(isSorted && sort.direction, styles.sortIcon)}</span>
                      </div>
                    </th>
                  );
                })}
                {filtersService && !isPivoted && (
                  <th className={styles.th}>
                    <Filters {...filtersService} />
                  </th>
                )}
              </tr>
            )}
          </thead>
        )}
        <tbody>
          {data &&
            data.map((row, index) => {
              const clickDisabled = disableClickAction && disableClickAction(row);
              return (
                <tr
                  key={keyGenerator ? keyGenerator(row) : `${row.id}-${index}`}
                  className={clsx(
                    styles.tr,
                    (!!onRowClick || clickableRow) && !clickDisabled && styles.clickableTr,
                    isRowSelected && isRowSelected(row) && styles.selectedRow,
                  )}
                  onClick={event => rowClickHandler(event, row)}
                >
                  {isPivoted ? (
                    <div className={styles.pivotedRow}>
                      {dataMapping.map(({ get, label, isAddon }, tdIndex) => (
                        <>
                          {!isAddon && <div className={styles.pivotedLabel}>{label}:</div>}
                          <td key={`${row.id}-${tdIndex}`} className={clsx(isAddon && styles.addonTd)}>
                            <span className={styles.tdContent}>{isNil(get(row)) ? t(...table_messages.no_data_cell) : get(row)}</span>
                          </td>
                        </>
                      ))}
                    </div>
                  ) : (
                    dataMapping.map(({ get, width, isAddon }, tdIndex) => (
                      <Tooltip key={`${row.id}-${tdIndex}`} enterDelay={700} title={clickDisabled || ''}>
                        <td className={clsx(styles.td, isAddon && styles.addonTd)} width={width}>
                          <span className={styles.tdContent}>{isNil(get(row)) ? t(...table_messages.no_data_cell) : get(row)}</span>
                        </td>
                      </Tooltip>
                    ))
                  )}
                  {filtersService && !isPivoted && <td className={styles.td} />}
                </tr>
              );
            })}
        </tbody>
      </table>
      {noData ? <div className={styles.noData}>{t(...table_messages.no_data_table)}</div> : null}
      {refetching && <CircularProgress className={styles.loader} color='primary' size={60} />}
    </div>
  );
};

const FiltersPropTypes = PropTypes.arrayOf(
  PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    name: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
  }),
);

Table.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({})),
  onRowClick: PropTypes.func,
  dataMapping: PropTypes.arrayOf(
    PropTypes.shape({ id: PropTypes.string, label: PropTypes.string, get: PropTypes.func, customSort: PropTypes.func }),
  ).isRequired,
  filtersService: PropTypes.shape({
    availableFilters: PropTypes.objectOf(
      PropTypes.shape({
        sectionName: PropTypes.arrayOf(PropTypes.string),
        filters: FiltersPropTypes,
      }),
    ),
    filters: PropTypes.objectOf(PropTypes.array),
    setFilters: PropTypes.func,
  }),
  sort: PropTypes.shape({
    key: PropTypes.string,
    direction: PropTypes.oneOf([DIRECTIONS.DESC, DIRECTIONS.ASC]),
    additionalInfo: PropTypes.string,
  }),
  onSort: PropTypes.func,
  hideThead: PropTypes.bool,
  fixedHeader: PropTypes.bool,
  getRowHref: PropTypes.func,
  clickableRow: PropTypes.bool,
  enableSearch: PropTypes.bool,
  onSearch: PropTypes.func,
  openInNewTab: PropTypes.bool,
  disableClickAction: PropTypes.func,
  isRowSelected: PropTypes.func,
  keyGenerator: PropTypes.func,
};

Table.defaultProps = {
  data: null,
  filtersService: null,
  onRowClick: null,
  sort: {},
  onSort: () => {},
  onSearch: () => {},
  hideThead: false,
  fixedHeader: false,
  getRowHref: null,
  clickableRow: false,
  enableSearch: false,
  disableClickAction: null,
  isRowSelected: null,
  keyGenerator: null,
};

export default Table;
