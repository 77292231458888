import React, { useEffect } from 'react';

import { Typography } from '@material-ui/core';
import { format, subMonths } from 'date-fns';
import { useFormik } from 'formik';
import { useSnackbar } from 'notistack';
import PubSub from 'pubsub-js';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import * as yup from 'yup';

import CenteredGrid from 'components/CenteredGrid/CenteredGrid';
import ColoredButton from 'components/ColoredButton';
import managementReportsApi from 'config/api/managementReports/managementReports';
import { ManagementReportPartialUpdateForm } from 'config/api/managementReports/managementReports.types';
import QUERY_KEYS from 'config/api/QUERY_KEYS';
import pubsub from 'config/events/pubsub';
import general_messages from 'messages/general_messages';
import manage_dashboard_messages from 'messages/manage_dasboard_messages';
import validation_messages from 'messages/validation_messages';
import { Id } from 'types/Id';

import DATE_FORMAT from '../../../config/constants/DATE_FORMAT';
import { useConfirmationModalContext } from '../../../reactContext/ConfirmationModalContext/ConfirmationModalContext';
import FormDateInput from '../../FormDateInput';
import ButtonsGrid from '../_components/ButtonsGrid';
import DialogGrid from '../_components/DialogGrid';

type Props = {
  open: boolean;
  onClose: () => void;
  reportId: Id | null;
  shouldRefreshView: boolean;
};

export type FormInput = Partial<ManagementReportPartialUpdateForm>;

const FORM_ID = `ManagementReportDialog`;

const ManagementReportDialog: React.FC<Props> = ({ open, onClose, reportId, shouldRefreshView }) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  const updateMutation = useMutation(managementReportsApi.partialUpdateManagementReport(reportId!));
  const createMutation = useMutation(managementReportsApi.createManagementReport);
  const checkOverlapping = useMutation(managementReportsApi.checkOverlapping);

  const { showConfirmationModal } = useConfirmationModalContext();

  const onSubmit = async (formData: FormInput) => {
    const mutationInput = { dateFrom: formData.dateFrom!, dateTo: formData.dateTo! };
    const overlappingReports = await checkOverlapping.mutateAsync(mutationInput);

    if (overlappingReports.length) {
      const continueWithOverlapping = await showConfirmationModal({
        title: t(manage_dashboard_messages.overlapping_reports.warning_title),
        body: (
          <>
            <Typography>{t(manage_dashboard_messages.overlapping_reports.warning_body)}</Typography>
            <ul>
              {overlappingReports.map(({ id, dateFrom, dateTo }) => (
                <Typography key={id} component='li'>
                  {format(dateFrom, DATE_FORMAT.defaultDate)} - {format(dateTo, DATE_FORMAT.defaultDate)}
                </Typography>
              ))}
            </ul>
            <Typography>{t(manage_dashboard_messages.overlapping_reports.warning_body_question)}</Typography>
          </>
        ),
      });
      if (!continueWithOverlapping) return;
    }
    if (reportId) {
      await updateMutation.mutateAsync(mutationInput);
      if (shouldRefreshView) {
        queryClient.invalidateQueries([QUERY_KEYS.GET_MANAGEMENT_REPORT, reportId]);
        PubSub.publish(pubsub.SELECTED_MANAGEMENT_REPORTS_UPDATED);
      }
    } else {
      await createMutation.mutateAsync(mutationInput);
    }
    PubSub.publish(pubsub.MANAGEMENT_REPORTS_UPDATED);
    enqueueSnackbar(t(general_messages.data_saved), { variant: 'success' });
    onClose();
  };

  const { data: initialData } = useQuery(
    [QUERY_KEYS.GET_MANAGEMENT_REPORT, reportId],
    () => managementReportsApi.getSingleManagementReport(reportId!),
    {
      enabled: !!reportId,
    },
  );

  const formik = useFormik<FormInput>({
    initialValues: {
      dateFrom: initialData?.dateFrom || subMonths(new Date(), 1),
      dateTo: initialData?.dateTo || new Date(),
    },
    validationSchema: yup.object({
      dateFrom: yup
        .date()
        .required(t(validation_messages.required))
        .test('date from test', t(validation_messages.date_from_cannot_be_after_date_to), (value, context) => {
          const { dateTo } = context.parent;
          return dateTo && value ? new Date(value) < new Date(dateTo) : true;
        }),
      dateTo: yup
        .date()
        .required(t(validation_messages.required))
        .test('date to test', t(validation_messages.date_to_cannot_be_before_date_from), (value, context) => {
          const { dateFrom } = context.parent;
          return dateFrom && value ? new Date(value) > new Date(dateFrom) : true;
        }),
    }),
    onSubmit,
  });

  useEffect(() => {
    if (initialData) {
      formik.setValues({
        dateTo: initialData.dateTo,
        dateFrom: initialData.dateFrom,
      });
    }
  }, [initialData]);

  const isMutating = updateMutation.isLoading || createMutation.isLoading;

  return (
    // @ts-ignore
    <DialogGrid
      dialogActions={
        <ButtonsGrid>
          {/* @ts-ignore */}
          <ColoredButton customColor='none' disabled={isMutating} onClick={onClose} variant='outlined'>
            {t(general_messages.cancel)}
          </ColoredButton>
          {/* @ts-ignore */}
          <ColoredButton customColor='secondary' disabled={isMutating} form={FORM_ID} type='submit' variant='outlined'>
            {reportId ? t(general_messages.save) : t(general_messages.add)}
          </ColoredButton>
        </ButtonsGrid>
      }
      onClose={onClose}
      open={open}
      title={reportId ? t(manage_dashboard_messages.dialog.title_edit) : t(manage_dashboard_messages.dialog.title_create)}
    >
      <form id={FORM_ID} onSubmit={formik.handleSubmit}>
        {/* @ts-ignore */}
        <CenteredGrid gridGap={2} withoutPadding>
          <FormDateInput
            dateTime={false}
            disableFuture
            formik={formik}
            id='dateFrom'
            label={t(manage_dashboard_messages.dialog.date_from)}
          />
          <FormDateInput dateTime={false} formik={formik} id='dateTo' label={t(manage_dashboard_messages.dialog.date_to)} />
        </CenteredGrid>
      </form>
    </DialogGrid>
  );
};

export default ManagementReportDialog;
