import React, { FC, useEffect, useMemo, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { generatePath } from 'react-router-dom';

import ColoredTile from 'components/ColoredTile';
import FooterButton from 'components/FooterButton';
import MobileGuttersContainer from 'components/MobileGuttersContainer';
import Section from 'components/Section';
import Table from 'components/Table';
import TextButton from 'components/TextButton/TextButton';
import TrimHTMLText from 'components/TrimHTMLText/TrimHTMLText';
import TypographyWithHTML from 'components/TypographyWithHTML/TypographyWithHTML';
import { legacyAssetRisksApi } from 'config/api/assetRisks/assetRisks';
import { parseAllAssetRisksForFE } from 'config/api/assetRisks/assetRisks.parsers';
import { AssetRisk } from 'config/api/assetRisks/assetRisks.types';
import pubsub from 'config/events/pubsub';
import usePagination from 'hooks/usePagination';
import usePrepareAvailableFilters from 'hooks/usePrepareAvailableFilters/usePrepareAvailableFilters';
import useSubscription from 'hooks/useSubscription';
import asset_risk_messages from 'messages/asset_risk_messages';
import general_messages from 'messages/general_messages';
import manage_dashboard_messages from 'messages/manage_dasboard_messages';
import { useHelpDialogContext } from 'reactContext/HelpDialogContext/HelpDialogContext';
import assetsImportanceFilter from 'services/_filters/parsers/_assetsFilters/assetsImportanceFilter';
import affectsIndividualsFilter from 'services/_filters/parsers/_riskAnalysisFilters/affectsIndividualsFilter';
import riskPlaceFilter from 'services/_filters/parsers/_riskAnalysisFilters/riskPlaceFilter';
import parseFiltersForRequest from 'services/parseFiltersForRequest';
import { Id } from 'types/Id';
import { TableDataMappingRow } from 'types/Table';

import PATHS from '../../../../router/PATHS';
import RiskQuadrantCell from '../../../SingleRiskAnalysisPage/_components/RiskQuadrantCell/RiskQuadrantCell';
import useGetManagementReportBaseData from '../../_hooks/useGetManagementReportBaseData';
import ManagementCommentInput from '../ManagementCommentInput/ManagementCommentInput';
import ManagementDashboardKPIGrid from '../ManagementDashboardKPIGrid/ManagementDashboardKPIGrid';
import ManagementDashboardSectionGrid from '../ManagementDashboardSectionGrid/ManagementDashboardSectionGrid';

type Props = {
  reportId?: Id;
};

const ManagementDashboardRisksSection: FC<Props> = ({ reportId }) => {
  const { t } = useTranslation();
  const { showHelpDialog } = useHelpDialogContext();

  const showIntroductionDialog = () => {
    showHelpDialog({
      title: t(manage_dashboard_messages.risks_section.introduction_title),
      body: t(manage_dashboard_messages.risks_section.introduction_body),
    });
  };

  const { data: baseData } = useGetManagementReportBaseData(reportId);

  const kpiData = useMemo(() => {
    const { risksReport } = baseData || {};
    return [
      {
        description: t(manage_dashboard_messages.risks_section.assets_analyzed_last_year_kpi_label),
        title: risksReport?.assetsAnalyzedLastYear ?? null,
      },
      {
        description: t(manage_dashboard_messages.risks_section.assets_not_analyzed_last_year_kpi_label),
        title: risksReport?.assetsNotAnalyzedLastYear ?? null,
      },
      {
        description: t(manage_dashboard_messages.risks_section.assets_with_q1_risk_kpi_label),
        title: risksReport?.assetsWithQ1Risk ?? null,
      },
      {
        description: t(manage_dashboard_messages.risks_section.assets_with_q2_risk_kpi_label),
        title: risksReport?.assetsWithQ2Risk ?? null,
      },
      {
        description: t(manage_dashboard_messages.risks_section.assets_with_q1_risk_not_to_handle_kpi_label),
        title: risksReport?.assetsWithQ1RiskNotToHandle ?? null,
      },
      {
        description: t(manage_dashboard_messages.risks_section.assets_with_q2_risk_not_to_handle_kpi_label),
        title: risksReport?.assetsWithQ2RiskNotToHandle ?? null,
      },
    ];
  }, [baseData, t]);

  const availableFilters = usePrepareAvailableFilters({
    affect_individuals: affectsIndividualsFilter,
    place: riskPlaceFilter,
    risk_analysis__asset__info__assets_importance: assetsImportanceFilter,
  });

  const [sort, setSort] = useState<{ key: string | null; direction: string | null }>({ key: null, direction: null });
  const onSort = (key: string, direction: string) => setSort({ key, direction });
  const [filters, setFilters] = useState({});

  const {
    data: tableData,
    getData: getTableData,
    hasNextPage,
    loading,
    refreshData,
  } = usePagination({
    endpointFunc: legacyAssetRisksApi.getAllAssetRisks({ management_report: reportId }),
    limits: [5, 5, 5],
    isGet: true,
    sort,
    dependencies: [filters],
    filters: parseFiltersForRequest(filters, {}, availableFilters || {}),
  });

  useEffect(() => {
    refreshData();
  }, [reportId]);

  useSubscription(pubsub.SELECTED_MANAGEMENT_REPORTS_UPDATED, refreshData);

  const dataMapping: TableDataMappingRow<AssetRisk>[] = useMemo(
    () => [
      { label: t(asset_risk_messages.risk_table.risk_label), id: 'name', get: data => data.name, width: '20%' },
      {
        label: t(asset_risk_messages.risk_table.consequence_label),
        id: 'primary_consequence',
        get: data => (
          <TrimHTMLText linesToShow={2}>
            <TypographyWithHTML>{data.primaryConsequence}</TypographyWithHTML>
          </TrimHTMLText>
        ),
        width: '26%',
      },
      {
        label: t(asset_risk_messages.risk_table.priority_label),
        id: 'risk_quadrant',
        get: data => <RiskQuadrantCell consequence={data.consequence} probability={data.probability} riskQuadrant={data.riskQuadrant} />,
        width: '20%',
      },
      {
        label: t(asset_risk_messages.risk_table.decision_label),
        id: 'decision',
        get: data => t(asset_risk_messages.risk_decision[data.decision]),
        width: '10%',
      },
      {
        label: t(asset_risk_messages.risk_table.affects_ppl_label),
        id: 'affect_individuals',
        get: data => (data.affectIndividuals ? t(general_messages.yes) : t(general_messages.no)),
        width: '8%',
      },
      {
        label: t(asset_risk_messages.risk_table.place_label),
        id: 'place',
        get: data => t(asset_risk_messages.risk_place[data.place]),
        width: '10%',
      },
      {
        label: t(asset_risk_messages.risk_table.tasks_label),
        id: 'risk_tasks_count',
        get: data => data.riskTasksCount,
        width: '6%',
      },
    ],
    [t, tableData],
  );

  // TODO fix typying
  // @ts-ignore
  const getRowHref = ({ riskAnalysis }: AssetRisk) => generatePath(PATHS.RISK_ANALYSIS_DETAIL, { riskAnalysisId: riskAnalysis.id });

  return (
    <Section
      title={manage_dashboard_messages.risks_section.title}
      titleLineAddon={[
        <TextButton key='introduction' onClick={showIntroductionDialog}>
          {t(manage_dashboard_messages.risks_section.introduction_button)}
        </TextButton>,
      ]}
      // @ts-ignore
      titleWrapper={MobileGuttersContainer}
    >
      <ManagementDashboardSectionGrid>
        <ManagementDashboardKPIGrid>
          {kpiData.map(({ description, title }) => (
            <ColoredTile key={description} centered description={description} disableRipple isLoading={title === null} title={title} />
          ))}
        </ManagementDashboardKPIGrid>
        <div>
          <Table
            clickableRow
            data={tableData && parseAllAssetRisksForFE(tableData)}
            dataMapping={dataMapping}
            filtersService={{ filters, setFilters, availableFilters }}
            getRowHref={getRowHref}
            keyGenerator={(data: AssetRisk) => `${reportId}-${data.id}-${data.name}-${data.description}`}
            onSort={onSort}
            openInNewTab
            refetching={loading}
            sort={sort}
          />
          {hasNextPage && (
            // @ts-ignore
            <FooterButton loading={loading} onClick={getTableData}>
              {t(general_messages.show_more)}
            </FooterButton>
          )}
        </div>
        <MobileGuttersContainer>
          <ManagementCommentInput fieldKey='risksReport' initialValue={baseData?.risksReport.comment || ''} reportId={reportId} />
        </MobileGuttersContainer>
      </ManagementDashboardSectionGrid>
    </Section>
  );
};

export default ManagementDashboardRisksSection;
