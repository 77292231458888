const manage_dashboard_messages = {
  page_title: ['manage_dashboard>>page_title', 'Manage Dashboard'],
  from_to_date: ['manage_dashboard>>from_to_date', 'from {{from}} to {{to}}'],
  selected_report: {
    title: ['manage_dashboard>>selected_report>>title', 'Selected report'],
    no_selected_report: ['manage_dashboard>>selected_report>>no_selected_report', 'Select a report to view'],
    report_period: ['manage_dashboard>>selected_report>>report_period', 'Report period'],
    created_at: ['manage_dashboard>>selected_report>>created_at', 'Created at'],
    updated_at: ['manage_dashboard>>selected_report>>updated_at', 'Updated at'],
    created_by: ['manage_dashboard>>selected_report>>created_by', 'Created by'],
  },
  dialog: {
    title_create: ['manage_dashboard>>dialog>>title_create', 'Create new report'],
    title_edit: ['manage_dashboard>>dialog>>title_edit', 'Edit report period'],
    date_from: ['manage_dashboard>>dialog>>date_from', 'From'],
    date_to: ['manage_dashboard>>dialog>>date_to', 'To'],
  },
  comment_label: ['manage_dashboard>>comment_label', 'Comment'],
  heading_section: {
    introduction_button: ['manage_dashboard>>heading_section>>introduction_button', 'How does it work?'],
    introduction_title: ['manage_dashboard>>heading_section>>introduction_title', 'Management Dashboard. How does it work?'],
    introduction_body: ['manage_dashboard>>heading_section>>introduction_body', 'Lorem ipsum'],
    table_title: ['manage_dashboard>>heading_section>>table_title', 'Available reports'],
    add_report: ['manage_dashboard>>heading_section>>add_report', 'Create new report'],

    created_at_table_column: ['manage_dashboard>>heading_section>>created_at_table_column', 'Creation date'],
    updated_at_table_column: ['manage_dashboard>>heading_section>>updated_at_table_column', 'Last update date'],
    created_by_table_column: ['manage_dashboard>>heading_section>>created_by_table_column', 'Created by'],
    report_period_table_column: ['manage_dashboard>>heading_section>>report_period_table_column', 'Report period'],
  },
  security_section: {
    title: ['manage_dashboard>>security_section>>title', 'Security summary report'],
    introduction_button: ['manage_dashboard>>security_section>>introduction_button', 'How does it work?'],
    introduction_title: ['manage_dashboard>>security_section>>introduction_title', 'Security summary report. How does it work?'],
    introduction_body: [
      'manage_dashboard>>security_section>>introduction_body',
      `In this section user with proper permissions to can upload a new security report document, set a summary status, and give a summary comment for the period.`,
    ],
    upload_file_button_label: ['manage_dashboard>>security_section>>upload_file_button_label', 'Upload Security Report'],
    risk_status_select_label: ['manage_dashboard>>security_section>>risk_status_select_label', 'Risk status'],
    uploaded_document_label: ['manage_dashboard>>security_section>>uploaded_document_label', 'Uploaded document'],
  },
  risk_status: {
    low: ['>>manage_dashboard>>risk_status>>low', 'Low'], // don't touch without changes in API - it's connected
    medium: ['>>manage_dashboard>>risk_status>>medium', 'Medium'], // don't touch without changes in API - it's connected
    high: ['>>manage_dashboard>>risk_status>>high', 'High'], // don't touch without changes in API - it's connected
  },
  incidents_section: {
    title: ['manage_dashboard>>incidents_section>>title', 'Major incidents'],
    introduction_button: ['manage_dashboard>>incidents_section>>introduction_button', 'How does it work?'],
    introduction_title: ['manage_dashboard>>incidents_section>>introduction_title', 'Major incidents. How does it work?'],
    introduction_body: [
      'manage_dashboard>>incidents_section>>introduction_body',
      'This section summarises the incidents during the period.',
    ],
    total_incidents_kpi_label: ['manage_dashboard>>incidents_section>>total_incidents_kpi_label', 'Total'],
    gdpr_incidents_kpi_label: ['manage_dashboard>>incidents_section>>gdpr_incidents_kpi_label', 'GDPR'],
    ongoing_incidents_kpi_label: ['manage_dashboard>>incidents_section>>ongoing_incidents_kpi_label', 'Ongoing'],
    highest_incidents_kpi_label: ['manage_dashboard>>incidents_section>>highest_incidents_kpi_label', 'Highest severity'],
    high_incidents_kpi_label: ['manage_dashboard>>incidents_section>>high_incidents_kpi_label', 'High severity'],
    medium_incidents_kpi_label: ['manage_dashboard>>incidents_section>>medium_incidents_kpi_label', 'Medium severity'],
    low_incidents_kpi_label: ['manage_dashboard>>incidents_section>>low_incidents_kpi_label', 'Low severity'],
  },
  risks_section: {
    title: ['manage_dashboard>>risks_section>>title', 'Risks'],
    introduction_button: ['manage_dashboard>>risks_section>>introduction_button', 'How does it work?'],
    introduction_title: ['manage_dashboard>>risks_section>>introduction_title', 'Risks. How does it work?'],
    introduction_body: [
      'manage_dashboard>>risks_section>>introduction_body',
      'This section contains the risks from the last risk analysis performed for each asset',
    ],
    assets_analyzed_last_year_kpi_label: [
      'manage_dashboard>>risks_section>>assets_analyzed_last_year_kpi_label',
      'Assets analyzed last year',
    ],
    assets_not_analyzed_last_year_kpi_label: [
      'manage_dashboard>>risks_section>>assets_not_analyzed_last_year_kpi_label',
      'Assets not analyzed last year',
    ],
    assets_with_q1_risk_kpi_label: ['manage_dashboard>>risks_section>>assets_with_q1_risk_kpi_label', 'Assets with Q1 risk'],
    assets_with_q2_risk_kpi_label: ['manage_dashboard>>risks_section>>assets_with_q2_risk_kpi_label', 'Assets with Q2 risk'],
    assets_with_q1_risk_not_to_handle_kpi_label: [
      'manage_dashboard>>risks_section>>assets_with_q1_risk_not_to_handle_kpi_label',
      'Assets with Q1 risk not to handle',
    ],
    assets_with_q2_risk_not_to_handle_kpi_label: [
      'manage_dashboard>>risks_section>>assets_with_q2_risk_not_to_handle_kpi_label',
      'Assets with Q2 risk not to handle',
    ],
  },
  risk_tasks_section: {
    title: ['manage_dashboard>>risk_tasks_section>>title', 'Risk tasks'],
    introduction_button: ['manage_dashboard>>risk_tasks_section>>introduction_button', 'How does it work?'],
    introduction_title: ['manage_dashboard>>risk_tasks_section>>introduction_title', 'Risk tasks. How does it work?'],
    introduction_body: [
      'manage_dashboard>>risk_tasks_section>>introduction_body',
      'This section contains the risks tasks from the last risk analysis performed for each asset',
    ],
    not_started_tasks_kpi_label: ['manage_dashboard>>risk_tasks_section>>not_started_tasks_kpi_label', 'Not started'],
    started_tasks_kpi_label: ['manage_dashboard>>risk_tasks_section>>started_tasks_kpi_label', 'Started'],
    overdue_tasks_kpi_label: ['manage_dashboard>>risk_tasks_section>>overdue_tasks_kpi_label', 'Overdue'],
  },
  planned_activities_section: {
    title: ['manage_dashboard>>planned_activities_section>>title', 'Yearly planned activities'],
    introduction_button: ['manage_dashboard>>planned_activities_section>>introduction_button', 'How does it work?'],
    introduction_title: [
      'manage_dashboard>>planned_activities_section>>introduction_title',
      'Yearly planned activities. How does it work?',
    ],
    introduction_body: [
      'manage_dashboard>>planned_activities_section>>introduction_body',
      'This section contains the yearly planned activities during the period',
    ],
  },
  overlapping_reports: {
    warning_title: ['manage_dashboard>>overlapping_reports>>warning_title', 'Overlapping reports'],
    warning_body: ['manage_dashboard>>overlapping_reports>>warning_body', 'Selected report period overlaps with the following reports:'],
    warning_body_question: ['manage_dashboard>>overlapping_reports>>warning_body_question', 'Do you want to proceed?'],
  },
};

export default manage_dashboard_messages;
