import { parseUserForFE } from '../_parsers/parseUser';

import { OrganizationRiskAnalyses, OrganizationRiskAnalysesDTO } from './organizationRiskAnalyses.types';

// eslint-disable-next-line import/prefer-default-export
export const parseOrganizationRiskAnalysesForFE = (analyses: OrganizationRiskAnalysesDTO[]): OrganizationRiskAnalyses[] =>
  analyses.map(dto => ({
    asset: {
      id: dto.asset.id,
      owner: parseUserForFE(dto.asset.owner),
      key: dto.asset.key,
      name: dto.asset.name,
      info: {
        containsSensitiveIndividualInformation: dto.asset.info.contains_sensitive_individual_information,
        assetsImportance: dto.asset.info.assets_importance,
      },
      category: {
        id: dto.asset.category.id,
        name: dto.asset.category.name,
        nameTranslationKey: dto.asset.category.name_translation_key,
      },
    },
    createdAt: new Date(dto.created_at),
    date: new Date(dto.date),
    highestRiskQuadrant: dto.highest_risk_quadrant,
    id: dto.id,
    status: dto.status,
    risksToHandleNumber: dto.risks_to_handle,
    updatedAt: new Date(dto.updated_at),
  }));
