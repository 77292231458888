import { format } from 'date-fns';

import DATE_FORMAT from '../../constants/DATE_FORMAT';
import { parseUserForFE } from '../_parsers/parseUser';

import {
  AssetRiskTasks,
  AssetRiskTasksDTO,
  RiskDTO,
  Risk,
  CreateInputDto,
  CreateInputForm,
  UpdateInputDto,
  UpdateInputForm,
  AssetRiskTasksDTODetail,
  AssetRiskTasksDetail,
  RiskForUserDTO,
  RiskForUser,
  AssetRiskTasksForUserDTO,
  AssetRiskTasksForUser,
  AssetRiskTasksStatsDTO,
  AssetRiskTasksStats,
} from './assetRiskTasks.types';

const parseRiskForFE = (raw: RiskDTO): Risk => ({
  id: raw.id,
  name: raw.name,
  description: raw.description,
  consequence: raw.consequence,
  probability: raw.probability,
  riskQuadrant: raw.risk_quadrant,
  affectIndividuals: raw.affect_individuals,
  decision: raw.decision,
  place: raw.place,
  primaryConsequence: raw.primary_consequence,
  riskAnalysis: {
    id: raw.risk_analysis.id,
    createdAt: new Date(raw.risk_analysis.created_at),
    updatedAt: new Date(raw.risk_analysis.updated_at),
    date: new Date(raw.risk_analysis.date),
    status: raw.risk_analysis.status,
    asset: {
      key: raw.risk_analysis.asset.key,
      name: raw.risk_analysis.asset.name,
      owner: parseUserForFE(raw.risk_analysis.asset.owner),
      category: {
        id: raw.risk_analysis.asset.category.id,
        name: raw.risk_analysis.asset.category.name,
        nameTranslationKey: raw.risk_analysis.asset.category.name_translation_key,
      },
      info: {
        assetDescription: raw.risk_analysis.asset.info.asset_description,
        confidentiality: raw.risk_analysis.asset.info.confidentiality,
        assetsImportance: raw.risk_analysis.asset.info.assets_importance,
      },
      id: raw.risk_analysis.asset.id,
    },
  },
});

const parseRiskForUserForFE = (raw: RiskForUserDTO): RiskForUser => ({
  id: raw.id,
  name: raw.name,
  riskAnalysis: {
    id: raw.risk_analysis.id,
    createdAt: new Date(raw.risk_analysis.created_at),
    updatedAt: new Date(raw.risk_analysis.updated_at),
    date: new Date(raw.risk_analysis.date),
    status: raw.risk_analysis.status,
    asset: {
      key: raw.risk_analysis.asset.key,
      name: raw.risk_analysis.asset.name,
      owner: parseUserForFE(raw.risk_analysis.asset.owner),
      category: {
        id: raw.risk_analysis.asset.category.id,
        name: raw.risk_analysis.asset.category.name,
        nameTranslationKey: raw.risk_analysis.asset.category.name_translation_key,
      },
      info: {
        assetDescription: raw.risk_analysis.asset.info.asset_description,
      },
      id: raw.risk_analysis.asset.id,
    },
  },
});

export const parseAllAssetRiskTasksForFE = (dto: AssetRiskTasksDTO[]): AssetRiskTasks[] =>
  dto.map(task => ({
    id: task.id,
    comment: task.comment,
    assignedTo: parseUserForFE(task.assigned_to),
    dueDate: new Date(task.due_date),
    lastStatusDate: new Date(task.last_status_date),
    plannedAction: task.planned_action,
    description: task.description,
    status: task.status,
    risk: parseRiskForFE(task.risk),
  }));

export const parseAllAssetRiskTasksForUserForFE = (dto: AssetRiskTasksForUserDTO[]): AssetRiskTasksForUser[] =>
  dto.map(task => ({
    id: task.id,
    comment: task.comment,
    assignedTo: parseUserForFE(task.assigned_to),
    dueDate: new Date(task.due_date),
    lastStatusDate: new Date(task.last_status_date),
    plannedAction: task.planned_action,
    description: task.description,
    status: task.status,
    risk: parseRiskForUserForFE(task.risk),
  }));

export const parseSingleAssetRiskTasksForFE = (raw: AssetRiskTasksDTODetail): AssetRiskTasksDetail => ({
  id: raw.id,
  comment: raw.comment,
  assignedTo: parseUserForFE(raw.assigned_to),
  dueDate: new Date(raw.due_date),
  lastStatusDate: new Date(raw.last_status_date),
  plannedAction: raw.planned_action,
  description: raw.description,
  status: raw.status,
  risk: parseRiskForFE(raw.risk),
});

export const parseCreateAssetRiskFormInput = (formData: CreateInputForm): CreateInputDto => ({
  risk: formData.risk,
  planned_action: formData.plannedAction,
  assigned_to: formData.assignedTo[0].key,
  due_date: format(formData.dueDate, DATE_FORMAT.defaultDate),
  status: formData.status,
  description: formData.description,
  comment: formData.comment,
});

export const parseUpdateAssetRiskFormInput = (formData: UpdateInputForm): UpdateInputDto | null => {
  const result: UpdateInputDto = {};
  if (formData.comment) result.comment = formData.comment;
  if (formData.description) result.description = formData.description;
  if (formData.plannedAction) result.planned_action = formData.plannedAction;
  if (formData.assignedTo) result.assigned_to = formData.assignedTo[0].key;
  if (formData.status) result.status = formData.status;
  if (formData.dueDate) result.due_date = format(formData.dueDate, DATE_FORMAT.defaultDate);
  if (Object.keys(result).length === 0) return null;

  return result;
};

export const parseAssetRiskTasksStatsForFE = (dto: AssetRiskTasksStatsDTO): AssetRiskTasksStats => ({
  overdueTasks: dto.overdue_tasks,
  notStartedTasks: dto.not_started_tasks,
  startedTasks: dto.started_tasks,
  finishedTasks: dto.finished_tasks,
});
