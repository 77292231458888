const assets_messages = {
  page_header: ['assets>>page_header', 'Assets'],
  help: {
    title: ['assets>>help>>tile', 'Assets - how it works?'],
    body: [
      'assets>>help>>body',
      'It works perfectly - lorem ipsum, description is needed here, and <b>HTML</b> is also possible, so we need some more description here please',
    ],
  },
  buttons: {
    new_asset: ['assets>>buttons>>new_asset', 'New asset'],
    new_task: ['assets>>buttons>>new_task', 'New task'],
  },
  assets_table: {
    title: ['assets>>assets_table>>title', 'Assets overview'],
    name: ['assets>>assets_table>>name', 'Asset name'],
    owner: ['assets>>assets_table>>owner', 'Owner'],
    category: ['assets>>assets_table>>category', 'Category'],
    description: ['assets>>assets_table>>description', 'Description'],
  },
  activities_table: {
    title: ['assets>>activities_table>>title', 'Yearly task planner'],
    activity: ['assets>>activities_table>>activity', 'Asset Activity'],
    date: ['assets>>activities_table>>date', 'Date'],
    description: ['assets>>activities_table>>description', 'Description'],
    start_date: ['assets>>activities_table>>start_date', 'Start date'],
    end_date: ['assets>>activities_table>>end_date', 'End date'],
  },
  remove_asset_modal: {
    title: ['assets>>remove_modal>>title', 'Remove asset'],
    body: ['assets>>remove_modal>>body', 'Are you sure you want to delete "{{assetName}}" asset?'],
  },
  remove_asset_activity_modal: {
    title: ['assets>>remove_asset_activity_modal>>title', 'Remove asset activity'],
    body: ['assets>>remove_asset_activity_modal>>body', 'Are you sure you want to delete "{{activityName}}" asset activity?'],
  },
  add_asset_modal: {
    title: ['assets>>add_modal>>title', 'New Asset'],
  },
  add_year_activity_asset_modal: {
    title: ['assets>>add_year_activity_asset_modal>>title', 'New Task'],
    activity: ['assets>>add_year_activity_asset_modal>>activity', 'Asset Activity'],
    categories: ['assets>>add_year_activity_asset_modal>>categories', 'Categories'],
    company_notes: ['assets>>add_year_activity_asset_modal>>company_notes', 'Own description'],
  },
  year_activity_asset: {
    activity_description_title: [
      'assets>>year_activity_asset>>activity_description_title',
      'Key info regarding asset {{activityName}} (from pocket safe)',
    ],
    dates_title: ['assets>>year_activity_asset>>dates_title', 'When should the activity be performed?'],
    categories_title: ['assets>>year_activity_asset>>categories_title', 'What asset categories should perform the activity'],
    company_notes_title: ['assets>>year_activity_asset>>company_notes_title', 'Company notes'],
    templates_title: ['assets>>templates_title>>company_notes_title', 'Templates and downloads'],
    related_content_title: ['assets>>related_content_title>>company_notes_title', 'Related content'],
    begin_date: ['assets>>year_activity_asset>>begin_date', 'Start date'],
    end_date: ['assets>>year_activity_asset>>end_date', 'End date'],
  },
  information_label: {
    name: ['assets>>information_label>>name', 'Asset name'],
    name_description: ['assets>>information_label>>name_description', 'Asset name (description)'],
    category: ['assets>>information_label>>category', 'Asset category'],
    category_description: ['assets>>information_label>>category_description', 'Asset category (description)'],
    owner: ['assets>>information_label>>owner', 'Asset owner'],
    internal_asset_identity: ['assets>>information_label>>internal_asset_identity', 'Internal asset identity'],
    internal_asset_identity_description: [
      'assets>>information_label>>internal_asset_identity_description',
      'Internal asset identity (description)',
    ],
    asset_sub_category: ['assets>>information_label>>asset_sub_category', 'Asset subcategory'],
    asset_sub_category_description: ['assets>>information_label>>asset_sub_category_description', 'Asset subcategory (description)'],
    asset_description: ['assets>>information_label>>asset_description', 'Asset description'],
    asset_description_description: ['assets>>information_label>>asset_description_description', 'Asset description (description)'],

    confidentiality: ['assets>>information_label>>confidentiality', 'Confidentiality'],
    confidentiality_description: ['assets>>information_label>>confidentiality_description', 'Confidentiality (description)'],
    integrity_change_control: ['assets>>information_label>>integrity_change_control', 'Integrity change control'],
    integrity_change_control_description: [
      'assets>>information_label>>integrity_change_control_description',
      'Integrity change control (description)',
    ],
    assets_importance: ['assets>>information_label>>assets_importance', 'Assets importance'],
    assets_importance_description: ['assets>>information_label>>assets_importance_description', 'Assets importance (description)'],
    availability: ['assets>>information_label>>availability', 'Availability'],
    availability_description: ['assets>>information_label>>availability_description', 'Availability (description)'],

    placement_of_asset: ['assets>>information_label>>placement_of_asset', 'Placement of asset'],
    placement_of_asset_description: ['assets>>information_label>>placement_of_asset_description', 'Placement of asset (description)'],
    current_status_of_asset: ['assets>>information_label>>current_status_of_asset', 'Current status of asset'],
    current_status_of_asset_description: [
      'assets>>information_label>>current_status_of_asset_description',
      'Current status of asset (description)',
    ],
    economical_value_of_asset: ['assets>>information_label>>economical_value_of_asset', 'Economical value of asset'],
    economical_value_of_asset_description: [
      'assets>>information_label>>economical_value_of_asset_description',
      'Economical value of asset (description)',
    ],

    legal_title: ['assets>>information_label>>legal_title', 'Legal'],
    legal_demands_checkbox: [
      'assets>>information_label>>legal_demands_checkbox',
      'The asset is under some sort of legal control/demand or insurance requirement',
    ],
    legal_demands_checkbox_description: ['assets>>information_label>>legal_demands_description', 'Legal demands (description)'],
    legal_demands_input_label: ['assets>>information_label>>legal_demands_input_label', 'What requierments?'],

    gdpr_title: ['assets>>information_label>>gdpr_title', 'GDPR'],
    identifiable_information_checkbox: [
      'assets>>information_label>>identifiable_information_checkbox',
      'Contains identifiable information about individuals',
    ],
    identifiable_information_checkbox_description: [
      'assets>>information_label>>identifiable_information_checkbox_description',
      'Identifiable information (description)',
    ],
    identifiable_information_input_label: [
      'assets>>information_label>>identifiable_information_input_label',
      'What identifiable information about individuals',
    ],

    sensitive_individual_checkbox: [
      'assets>>information_label>>sensitive_individual_checkbox',
      'Contains sensitive individual information',
    ],
    sensitive_individual_checkbox_description: [
      'assets>>information_label>>sensitive_individual_checkbox_description',
      'Sensitive individual information (description)',
    ],
    sensitive_individual_input_label: [
      'assets>>information_label>>sensitive_individual_input_label',
      'What identifiable information about individuals',
    ],

    fill_details_checkbox: ['assets>>information_label>>fill_details_checkbox', 'I want to fill out the Asset details now at once'],

    section_title_1: ['assets>>information_label>>section_title_1', ''],
    section_title_2: ['assets>>information_label>>section_title_2', ''],
    section_title_3: ['assets>>information_label>>section_title_3', 'Topic 3'],
    section_title_4: ['assets>>information_label>>section_title_4', 'Topic 4'],

    friendly_id_label: ['assets>>information_label>>friendly_id_label', 'Asset id'],

    files_title: ['assets>>information_label>>files_title', 'Files'],
    files_description: ['assets>>information_label>>files_description', 'Securely store files related to this asset'],
    contacts_title: ['assets>>information_label>>contacts_title', 'Important contacts'],
    filename: ['assets>>information_label>>filename', 'Filename'],
    files_label: ['assets>>information_label>>files_label', 'Document name'],
  },
  edit_page: {
    title: ['assets>>edit_page>>title', 'Assets'],
  },
  information_choices_assets_importance: {
    very_high_importance: ['assets>>information_choices_assets_importance>>very_high_importance', 'Very high importance'], // don't touch without changes in API - it's connected
    high_importance: ['assets>>information_choices_assets_importance>>high_importance', 'High importance'], // don't touch without changes in API - it's connected
    low_importance: ['assets>>information_choices_assets_importance>>low_importance', 'Low importance'], // don't touch without changes in API - it's connected
  },
  information_choices_availability: {
    non_critical: ['assets>>information_choices_availability>>non_critical', 'Non critical asset'],
    less_critical: ['assets>>information_choices_availability>>less_critical', 'Less critical asset'],
    critical: ['assets>>information_choices_availability>>critical', 'Critical asset'],
    very_critical: ['assets>>information_choices_availability>>very_critical', 'Very critical asset'],
  },
  information_choices_confidentiality: {
    open_information: ['assets>>information_choices_confidentiality>>open_information', 'open_information'],
    internal_information: ['assets>>information_choices_confidentiality>>internal_information', 'internal_information'],
    confidential_information: ['assets>>information_choices_confidentiality>>confidential_information', 'confidential_information'],
    strict_confidential_information: [
      'assets>>information_choices_confidentiality>>strict_confidential_information',
      'strict_confidential_information',
    ],
  },
  information_choices_integrity_change_control: {
    no_demands: ['assets>>information_choices_integrity_change_control>>no_demands', 'no_demands'],
    some_demands: ['assets>>information_choices_integrity_change_control>>some_demands', 'some_demands'],
    demands: ['assets>>information_choices_integrity_change_control>>demands', 'demands'],
    high_demands: ['assets>>information_choices_integrity_change_control>>high_demands', 'high_demands'],
  },
  details_page: {
    title: ['assets>>details_page>>title', `Asset`],
    upload_file_button: ['assets>>details_page>>upload_file_button', 'Upload file'],
    add_contact_button: ['assets>>details_page>>add_contact_button', 'Add contact'],
  },
  details_activities_page: {
    title: ['assets>>details_activities_page>>title', `Asset activity`],
  },
  contact: {
    dialog_title_add: ['assets>>contact>>dialog_title', 'Add contact'],
    dialog_title_edit: ['assets>>contact>>dialog_title', 'Edit contact'],
    comment_label: ['assets>>contact>>comment_label', 'Comment'],
    category_label: ['assets>>contact>>category_label', 'Category'],
    name_label: ['assets>>contact>>category_label', 'Contact name'],
  },
  asset_tasks_introduction: {
    button: ['assets>>asset_tasks_introduction>>button', 'Introduction'],
    title: ['assets>>asset_tasks_introduction>>title', 'Yearly asset activities - how it works?'],
    body: [
      'assets>>asset_tasks_introduction>>body',
      'It works perfectly - lorem ipsum, description is needed here, and <b>HTML</b> is also possible, so we need some more description here please',
    ],
  },
  asset_tasks_introduction_dashboard: {
    button: ['assets>>asset_tasks_introduction_dashboard>>button', 'Introduction'],
    title: ['assets>>asset_tasks_introduction_dashboard>>title', 'Yearly asset activities dashboard - how it works?'],
    body: [
      'assets>>asset_tasks_introduction_dashboard>>body',
      'It works perfectly - lorem ipsum, description is needed here, and <b>HTML</b> is also possible, so we need some more description here please',
    ],
  },
  asset_involved_assets_introduction: {
    button: ['assets>>asset_involved_assets_introduction>>button', 'Introduction'],
    title: ['assets>>asset_involved_assets_introduction>>title', 'Assets related to Incident Report - how it works?'],
    body: [
      'assets>>asset_involved_assets_introduction>>body',
      'It works perfectly - lorem ipsum, description is needed here, and <b>HTML</b> is also possible, so we need some more description here please',
    ],
  },
  asset_tasks_table: {
    task_name: ['assets>>asset_tasks_table>>task_name', 'Task name'],
    asset_name: ['assets>>asset_tasks_table>>asset_name', 'Asset name'],
    asset_category: ['assets>>asset_tasks_table>>asset_category', 'Asset type'],
    due_date: ['assets>>asset_tasks_table>>due_date', 'Due date'],
    status: ['assets>>asset_tasks_table>>status', 'Status'],
    empty_list: ['assets>>asset_tasks_table>>empty_list', 'No tasks found'],
    owner: ['assets>>asset_tasks_table>>owner', 'Owner'],
    activity: ['assets>>asset_tasks_table>>activity', 'Activity name'],
    asset_filter_name: ['assets>>asset_tasks_table>>asset_filter_name', 'Asset'],
    asset_overdue_filter_name: ['assets>>asset_tasks_table>>asset_overdue_filter_name', 'Overdue'],
    asset_owner_filter_name: ['assets>>asset_tasks_table>>asset_owner_filter_name', 'Asset owner'],
    asset_activity_filter_name: ['assets>>asset_tasks_table>>asset_activity_filter_name', 'Asset activity'],
    asset_place_filter_name: ['assets>>asset_tasks_table>>asset_place_filter_name', 'Place'],
  },
  asset_task_status: {
    finished: ['assets>>asset_task_status>>finished', 'Finished'],
    not_started: ['assets>>asset_task_status>>not_started', 'Not started'],
    started: ['assets>>asset_task_status>>started', 'Started'],
  },
  asset_task_dialog: {
    planner_activity_label: ['assets>>asset_task_dialog>>planner_activity_label', 'Planner activity'],
    due_date_label: ['assets>>asset_task_dialog>>due_date_label', 'Due date'],
    owner_label: ['assets>>asset_task_dialog>>owner_label', 'Owner'],
    status_label: ['assets>>asset_task_dialog>>status_label', 'Status'],
    comment_label: ['assets>>asset_task_dialog>>comment_label', 'Comment'],
    files_label: ['assets>>asset_task_dialog>>files_label', 'Files'],
    files_description: ['assets>>asset_task_dialog>>files_description', 'Upload files related to this task'],
  },
  tasks_page: {
    title: ['assets>>tasks_page>>title', 'Asset Tasks'],
  },
  tasks_kpi: {
    // don't change - used in API
    notStarted: ['assets>>tasks_kpi>>notStarted', 'Not started'],
    started: ['assets>>tasks_kpi>>started', 'Started'],
    finished: ['assets>>tasks_kpi>>finished', 'Finished'],
    overdue: ['assets>>tasks_kpi>>overdue', 'Overdue'],
  },
};

export default assets_messages;
